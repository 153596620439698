<template>
  <b-card>
      <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Select Product')" label-for="productId">
                  <validation-provider #default="{ errors }" rules="required" name="productId">
                    <v-select
                      v-model="orderForm.productId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productList"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      @input="onChangeProduct"
                      :state="errors.length > 0 ? false:null"
                      input-id="productId"
                      label="displayName"
                      placeholder="Select Product"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Sub Total')" label-for="subtotal">
                  <validation-provider #default="{ errors }" rules="required" name="subtotal">
                    <b-form-input id="subtotal" v-model="orderForm.subtotal" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Currency Unit')" label-for="currencyUnit">
                  <validation-provider #default="{ errors }" rules="required" name="currencyUnit">
                    <b-form-input id="currencyUnit" v-model="orderForm.currencyUnit" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Select Customer')" label-for="productId">
                  <validation-provider #default="{ errors }" rules="required" name="Type">
                    <v-select
                      v-model="orderForm.userId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userList"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      label="userNameAndMail"
                      placeholder="Select Customer"
                      :state="errors.length > 0 ? false:null"
                      input-id="productId"
                      :loading="loading"
                      @search="fetchUsers"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Order Status')" label-for="orderStatus">
                  <v-select
                    v-model="orderForm.orderStatus"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="orderStatusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="orderStatus"
                    placeholder="Select Status" :disabled="true"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Payment Type')" label-for="paymentData_paymentType">
                  <v-select
                    v-model="orderForm.paymentType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentTypeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="paymentData_paymentType"
                    placeholder="Select Payment Type" :disabled="true"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="createItem.bind(this, true)"
              :onClickSave="createItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import ApiService from '@/common/api.service'
// Alert
import AlertService from '@/common/alert.service'
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';

// Options
import StaticOptions from '@/common/options/StaticOptions';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return { 
      required,
      min,
      max,

      orderStatusOptions: StaticOptions.orderStatusOptions,
      paymentTypeOptions: StaticOptions.paymentTypeOptions,

      userList: [],
      search: '',
      loading: false,

      productList: [],

      orderForm: {
        productId: null,
        subtotal: null,
        currencyUnit: null,
        userId: null,
        paymentType: 1,
        orderStatus: 1,
      },
    }
  },
  methods: {
    onChangeProduct(productId) {
      const findProduct = this.productList.find(x => x.id === productId);
      if (findProduct) {
        this.orderForm.subtotal = findProduct.disCountedPrice;
        this.orderForm.currencyUnit = findProduct.currencyUnit
      }
    },
    fetchUsers(search) {
      /* eslint-disable */
      if (search === 'INITIALIZ-SEARCH') {
        search = '';
      } else {
        if (search.length < 3) {
          this.items = [];
          return;
        }
      }

      this.loading = true;

      ApiService.get(`user/selects/${search}`, {}, this)
        .then(response => {
          this.userList = response.result;
          this.loading = false;
        })
    },
    fetchProducts() {
      ApiService.get('Product/getall', {}, this)
        .then(response => {
          if (response.statusCode === 200) {
          this.productList = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    createItem() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          ApiService.post('order/create', this.orderForm, this)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                this.$router.push({ name: 'manage-order-save', params: { id: response.result } })
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      });
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchUsers('INITIALIZ-SEARCH');
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
