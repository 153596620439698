var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Select Product'),"label-for":"productId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"productId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productList,"reduce":function (val) { return val.id; },"clearable":false,"state":errors.length > 0 ? false:null,"input-id":"productId","label":"displayName","placeholder":"Select Product"},on:{"input":_vm.onChangeProduct},model:{value:(_vm.orderForm.productId),callback:function ($$v) {_vm.$set(_vm.orderForm, "productId", $$v)},expression:"orderForm.productId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Sub Total'),"label-for":"subtotal"}},[_c('validation-provider',{attrs:{"rules":"required","name":"subtotal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subtotal","state":errors.length > 0 ? false:null},model:{value:(_vm.orderForm.subtotal),callback:function ($$v) {_vm.$set(_vm.orderForm, "subtotal", $$v)},expression:"orderForm.subtotal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Currency Unit'),"label-for":"currencyUnit"}},[_c('validation-provider',{attrs:{"rules":"required","name":"currencyUnit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"currencyUnit","state":errors.length > 0 ? false:null},model:{value:(_vm.orderForm.currencyUnit),callback:function ($$v) {_vm.$set(_vm.orderForm, "currencyUnit", $$v)},expression:"orderForm.currencyUnit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Select Customer'),"label-for":"productId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.userList,"reduce":function (val) { return val.id; },"clearable":false,"label":"userNameAndMail","placeholder":"Select Customer","state":errors.length > 0 ? false:null,"input-id":"productId","loading":_vm.loading},on:{"search":_vm.fetchUsers},model:{value:(_vm.orderForm.userId),callback:function ($$v) {_vm.$set(_vm.orderForm, "userId", $$v)},expression:"orderForm.userId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Order Status'),"label-for":"orderStatus"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.orderStatusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"orderStatus","placeholder":"Select Status","disabled":true},model:{value:(_vm.orderForm.orderStatus),callback:function ($$v) {_vm.$set(_vm.orderForm, "orderStatus", $$v)},expression:"orderForm.orderStatus"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Payment Type'),"label-for":"paymentData_paymentType"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.paymentTypeOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"paymentData_paymentType","placeholder":"Select Payment Type","disabled":true},model:{value:(_vm.orderForm.paymentType),callback:function ($$v) {_vm.$set(_vm.orderForm, "paymentType", $$v)},expression:"orderForm.paymentType"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('save-button',{attrs:{"onClickSaveAndClose":_vm.createItem.bind(this, true),"onClickSave":_vm.createItem.bind(this, false)}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }